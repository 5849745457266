import React, { useState } from "react";
import {
  Input as AntInput,
  AutoComplete as AntAutoComplete,
  Form as AntForm,
  DatePicker as AntDatePicker,
  Select as AntSelect,
  Collapse as AntCollapse,
  Button,
  Checkbox as AntCheckBox,
  Upload,
} from "antd";
import {
  CaretDownOutlined,
  DeleteOutlined,
  InboxOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  CheckSquareOutlined,
  SearchOutlined,
  DownOutlined,
  RightOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { PrimaryButton } from "../Button";
import { IconCSV, IconAttachment } from "../Icons";
import {
  RightArrowIcon,
  DownArrowIcon,
  CalenderIcon,
  ReferenceLinkIcon,
} from "../Assets";
import { Row, Col } from "antd";
import dayjs from "dayjs";
import "./style.scss";

const getMaxLengthRule = (maxLength) => {
  let max = null;
  if (maxLength !== undefined) {
    max = {
      show: true,
      max: maxLength,
      strategy: (txt) => String(txt).split("").length,
      exceedFormatter: (txt, { max }) =>
        String(txt).split("").slice(0, max).join(""),
    };
  }
  return max;
};

const Label = (props) => {
  const { text, required, className } = props;
  const showColon = props.showColon !== undefined ? props.showColon : false;
  return text && text.length > 0 ? (
    <span
      className={`f14 c00085 reg label ${className || ""} ${
        required ? "req" : ""
      }`}
    >
      {text}
      {showColon && ":"}
    </span>
  ) : null;
};

const FileIcon = (props) => {
  const { type, showColon, ...rest } = props;
  let IconComponent = FileOutlined;
  switch (type) {
    case "image/png":
      IconComponent = FileImageOutlined;
      break;
    case "image/jpg":
    case "image/jpeg":
      IconComponent = FileJpgOutlined;
      break;
    case "application/pdf":
      IconComponent = FilePdfOutlined;
      break;
    case "text/csv":
      IconComponent = ({ className, showColon, ...rest }) => {
        return <IconCSV className={`${className || ""}`} {...rest} />;
      };
      break;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      IconComponent = FileWordOutlined;
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
      IconComponent = FileExcelOutlined;
      break;
  }
  return <IconComponent {...rest} />;
};

const HelpText = (props) => {
  const { helpText, error, type } = props;
  return Boolean(helpText) || Boolean(error) ? (
    <div className="col">
      {String(error || "").trim().length > 0 && (
        <React.Fragment>
          <span className="f10 cFE3333">{error}</span>
          <br />
        </React.Fragment>
      )}
      {String(helpText || "").trim().length > 0 && (
        <React.Fragment>
          <span
            dangerouslySetInnerHTML={{ __html: helpText }}
            className="f10 c989898 help-text"
          ></span>
          {type === "suffix-pass" && (
            <span className="f10 c989898">
              Click{" "}
              <CheckSquareOutlined
                style={{ fontSize: "10px", color: "#989898" }}
              />{" "}
              to copy the password to clipboard
            </span>
          )}
        </React.Fragment>
      )}
    </div>
  ) : null;
};

const SelectSuffix = (props) => {
  const { allowSearch, disabled, iconColor } = props;
  const SuffixIcon = allowSearch ? SearchOutlined : CaretDownOutlined;
  const defaultStyle = {
    fontSize: 16,
    color: disabled ? "#D9D9D9" : iconColor ? iconColor : "#1133CC",
    pointerEvents: "none",
  };
  return <SuffixIcon style={defaultStyle} />;
};

export const TextInput = React.forwardRef((props, ref) => {
  const {
    type,
    id,
    label,
    labelFont,
    labelClassName,
    placeholder,
    name,
    value,
    onChange,
    readOnly,
    maxLength,
    className,
    rootClassName,
    showColon,
    icon,
    iconColor,
    inputStyle,
    ...rest
  } = props;
  const count = getMaxLengthRule(maxLength);
  const overflow = rest.overflow !== undefined ? rest.overflow : true;
  const _className = `${rootClassName || "text-input"} border ${
    Boolean(count) ? "no-pad" : ""
  } ${props.font || "f14"} ${readOnly ? "read-only" : ""} ${
    overflow ? "" : "txt-no-wrap"
  } ${className || ""}`;
  const suffix = icon ? (
    <span className={`icon ${icon} ${iconColor || "c0133CC"}`}>{icon}</span>
  ) : null;

  return Boolean(readOnly) ? (
    <div className={_className} title={!overflow ? value : ""}>
      {value}
    </div>
  ) : (
    <AntInput
      style={inputStyle}
      ref={ref}
      type={type}
      name={name}
      className={_className}
      classNames={{ input: "input-ele", suffix: "input-suffix" }}
      id={`for-${id || name}`}
      value={value}
      onChange={onChange}
      count={count}
      suffix={suffix}
      placeholder={placeholder}
      {...rest}
    />
  );
});
const TextArea = (props) => {
  const {
    id,
    label,
    placeholder,
    name,
    value,
    onChange,
    readOnly,
    maxLength,
    className,
    showColon,
    isURL,
    ...rest
  } = props;
  const _className = `textarea-input border ${props.font || "f4"} ${
    readOnly ? "read-only" : ""
  } ${className || ""}`;
  // Function to convert \n to <br /> for rendering
  const renderValueWithLineBreaks = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {isURL && line ? (
          <a
            href={line}
            target="_blank"
            rel="noopener noreferrer"
            className="c0133CC"
          >
            {line}
            <ReferenceLinkIcon className="ml2" />
          </a>
        ) : (
          line
        )}
        {index < text.split("\n").length - 1 && <br />}{" "}
        {/* Add <br /> only if there are more lines */}
      </React.Fragment>
    ));
  };

  return (
    <div className={_className}>
      {Boolean(readOnly) ? (
        <span>{renderValueWithLineBreaks(value)}</span>
      ) : isURL ? (
        <AntInput.TextArea
          type="text"
          name={name}
          value={value}
          showCount={maxLength > 0}
          maxLength={maxLength}
          id={`for-${id || name}`}
          className={_className}
          onChange={onChange}
          placeholder={placeholder || label}
          style={{ color: "#0133CC", textDecoration: "None" }} // Style as link
          {...rest}
        />
      ) : (
        <AntInput.TextArea
          type="text"
          name={name}
          value={value}
          showCount={maxLength > 0}
          maxLength={maxLength}
          id={`for-${id || name}`}
          className={_className}
          onChange={onChange}
          placeholder={placeholder || label}
          {...rest}
        />
      )}
    </div>
  );
};
const Select = (props) => {
  const {
    multiple,
    readOnly,
    template,
    label,
    options,
    optionLabelClassName,
    value,
    onChange,
    name,
    placeholder,
    allowSearch,
    disabled,
    inputStyle,
  } = props;
  const getOptionClass = (data) =>
    `${name}-field-option ${String(data.id).toLowerCase()}`;
  const getValue = () => {
    let res;
    if (multiple) {
      res = [];
      if (Array.isArray(value)) {
        res = value.map(({ id }) => id);
      }
    } else {
      res = value && value.id ? value.label : null;
    }
    return res;
  };
  const handleChange = (iValue) => {
    let newValue;
    if (multiple) {
      newValue =
        iValue.length > 0
          ? (options || []).filter(({ id }) => iValue.includes(id))
          : [];
    } else {
      newValue = (options || []).find(({ id }) => id === iValue);
    }
    if (newValue) {
      onChange && onChange({ target: { name, value: newValue } });
    }
  };
  const isChecked = (iID) => {
    let index = Array.isArray(value)
      ? value.findIndex(({ id }) => id === iID)
      : -1;
    return index > -1;
  };
  const Option = (props) => {
    let { data, isPlaceholder } = props;
    let labelClassName = isPlaceholder
      ? "f14 c00025"
      : optionLabelClassName || "f14";
    if (isPlaceholder) {
      data = { id: "placeholder", label: placeholder };
    }
    const checked = !isPlaceholder && multiple ? isChecked(data.id) : false;
    return (
      <div
        className={`ant-option ${
          multiple ? "multi-option" : ""
        } row w-100 ${getOptionClass(data)}`}
        key={data.id}
      >
        {!isPlaceholder && multiple && (
          <div
            className={`col v-ctr h-ctr check-container ${
              checked ? "checked" : ""
            }`}
          >
            <AntCheckBox checked={checked} />
          </div>
        )}
        {template === "bullet" && (
          <div className={`bullet ${isPlaceholder ? "placeholder" : ""}`}></div>
        )}
        <span className={`col reg f-rest c00085 ${labelClassName}`}>
          {data.label}
        </span>
      </div>
    );
  };
  const tagRender = () => {
    const data = Array.isArray(value) && value.length > 0 ? value[0] : null;
    let labelClassName = optionLabelClassName || "f14";
    if (!data) return null;
    return (
      <div
        className={`tag-value ant-option o-hide row w-100 ${getOptionClass(
          data
        )}`}
        key={data.id}
      >
        {template === "bullet" && <div className="bullet"></div>}
        <div className={`col f-rest max-w-100`}>
          <div className="row w-100 h-btn">
            <div className="col f-rest o-hide">
              <span className={`c00085 option-text w-100 ${labelClassName}`}>
                {data.label}
              </span>
            </div>
            {Array.isArray(value) && value.length > 1 && (
              <span className="col extra-count v-ctr h-ctr">
                <span className="c1A32C4 f11">+{value.length - 1}</span>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };
  const getRootClassName = () => {
    let _className = `select-input`;
    _className += readOnly ? " read-only" : "";
    _className += multiple ? " multiple" : "";
    _className += allowSearch ? " search" : "";
    if (Array.isArray(value) && value.length > 0) {
      _className += value[0].className || "";
    } else if (value) {
      _className += value.className || "";
    }
    return _className;
  };
  const getOptions = () => {
    let res = Array.isArray(options) ? options : [];
    res = res.map((_) => ({ ..._, value: _.id }));
    return res;
  };
  const _props = {
    style: inputStyle,
    disabled: disabled || readOnly,
    onChange: handleChange,
    showSearch: allowSearch,
    popupClassName: "select-popup",
    placeholder: <Option isPlaceholder />,
    labelRender: () => <Option data={value} />,
    value: getValue(),
    rootClassName: getRootClassName(),
    suffixIcon: <SelectSuffix {...props} />,
    options: getOptions(),
    optionRender: (option) => {
      const { data } = option;
      return <Option data={data} />;
    },
    filterOption: (inputValue, option) => {
      return (
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
      );
    },
    maxTagCount: 1,
    notFoundContent: (
      <div className="select-no-found col v-ctr h-ctr">
        <span className="h12 text-center c777">
          No Matching {label || "Result"}
        </span>
      </div>
    ),
    tagRender: tagRender,
  };
  if (multiple) {
    _props.mode = "tags";
  }
  return <AntSelect {..._props} />;
};

const AutoComplete = (props) => {
  const [inputValue, setInputValue] = React.useState("");
  const { options, value, onChange, name, placeholder } = props;
  React.useEffect(() => {
    setInputValue(value ? value.label : "");
  }, [value]);
  const handleOnSelect = (e, option) => {
    onChange && onChange({ target: { name, value: option } });
  };
  // const handleChange = (value) => {
  //   setInputValue(value);
  //   if (value === "") {
  //     handleOnSelect(null, "");
  //   }
  // };
  const handleChange = (newValue) => {
    setInputValue(newValue);
    if (props.allowFreeType) {
      // Handle custom inputs
      if (!options.find((o) => o.label === newValue)) {
        onChange &&
          onChange({
            target: { name, value: { id: newValue, label: newValue } }, // Treat custom value as a new object
          });
      }
    }
    if (newValue === "") {
      handleOnSelect(null, ""); // Clear the value when input is empty
    }
  };
  return (
    <AntAutoComplete
      value={inputValue}
      onSelect={handleOnSelect}
      onChange={handleChange}
      optionRender={(option) => {
        return (
          <span className={`f14 ${option.className || ""}`} key={option.id}>
            {option.label}
          </span>
        );
      }}
      filterOption={(inputValue, option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
      }
      className="f-rest"
      popupClassName="select-popup"
      options={(options || []).map((_) => ({
        ..._,
        key: _.id,
        value: _.label,
      }))}
    >
      <TextInput
        suffix={<SelectSuffix {...props} />}
        placeholder={placeholder}
        rootClassName="select-input"
        className={`${value ? value.className : ""}`}
      />
    </AntAutoComplete>
  );
};

const FileInput = (props) => {
  const _fileInputRef = React.useRef(null);
  const {
    value,
    className,
    name,
    multiple,
    placeholder,
    label,
    accept,
    allowDelete,
  } = props;
  const handleOnFileChange = (e) => {
    let _files = Array.from(e.target.files);
    if (multiple) {
      _files = [...(value || []), ..._files];
    }
    props.onChange && props.onChange({ target: { name: name, value: _files } });
  };
  const handleSelectFile = (e) => {
    _fileInputRef.current && _fileInputRef.current.click();
  };
  const handleRemoveFile = (index) => {
    props.onDelete && props.onDelete(name, index);
  };
  return (
    <div className={`col ${className || ""}`}>
      {Boolean(
        multiple && allowDelete && Array.isArray(value) && value.length > 0
      ) && (
        <div className="col file-list w-100 oy-auto">
          {Array.prototype.map.call(value, (file, i) => {
            return (
              <div key={i} className="row file-entry w-100">
                <div className="row f-rest">
                  <FileIcon className="f14 file-icon" type={file.type} />
                  <span className="f14 reg c000 text-nxt">{file.name}</span>
                </div>
                <Button
                  type="button"
                  icon={
                    <DeleteOutlined style={{ fontSize: 12, color: "c00045" }} />
                  }
                  className="btn-delete"
                  variant="lite"
                  onClick={handleRemoveFile.bind(null, i)}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className={`row w-100`}>
        <div className="col text-input border h-ctr c00025">
          {Boolean(allowDelete && multiple) ||
          !Boolean(value) ||
          (Array.isArray(value) && value.length === 0) ? (
            <span className={`input-ele f14 reg ${props.placeholderClassName}`}>
              <span className="cC3C3C3">{placeholder || label}</span>
            </span>
          ) : Array.isArray(value) && value.length === 1 ? (
            <span className="input-ele f14 reg">{value[0].name}</span>
          ) : null}
        </div>
        <PrimaryButton
          label={props.btnLabel || "Select File"}
          labelClassName="cFFF"
          icon={<IconAttachment className="cFFF" />}
          className="min select-btn"
          type="button"
          onClick={handleSelectFile}
        />
        <input
          name={name}
          style={{ display: "none" }}
          ref={_fileInputRef}
          type="file"
          multiple={multiple}
          accept={accept}
          onChange={handleOnFileChange}
          hidden={true}
        />
      </div>
    </div>
  );
};
const HTMLInput = (props) => {
  const _ref = React.useRef(null);
  let {
    readOnly,
    disabled,
    value,
    name,
    onChange,
    toolbarType,
    config,
    onInstanceReady,
  } = props;
  if (!value || value === "undefined") {
    value = "";
  }
  const handleInstanceReady = (e) => {
    if (e.editor) {
      e.editor.setData(value);
    }
    onInstanceReady && onInstanceReady(e);
  };
  return (
    <React.Fragment>
      {readOnly || disabled ? (
        <p
          className="ck-container max oy-auto"
          dangerouslySetInnerHTML={{ __html: value }}
        ></p>
      ) : null}
    </React.Fragment>
  );
};
const DatePicker = (props) => {
  const {
    id,
    label,
    placeholder,
    name,
    value,
    onChange,
    readOnly,
    maxLength,
    className,
    showColon,
    rootClassName,
    minDate,
    ...rest
  } = props;
  const _className = `${rootClassName || "text-input"} border ${
    props.font || "f14"
  } ${readOnly ? "read-only" : ""}`;
  const handleChange = (date) => {
    onChange && onChange({ target: { name, value: date } });
  };
  const handleDisabledDate = (currentDate) => {
    let disabled = false;
    if (minDate) {
      let _minDate = minDate;
      if (minDate === "today") {
        _minDate = dayjs().toDate();
      }
      let cDate = currentDate.toDate();
      disabled = dayjs(cDate).isBefore(_minDate);
    }
    return disabled;
  };
  return Boolean(readOnly) ? (
    <div className={_className}>
      {props.prefixIcon}
      {value}
    </div>
  ) : (
    <AntDatePicker
      name={name}
      format="ddd, DD-MM-YY"
      placeholder={placeholder || label}
      className={_className}
      onChange={handleChange}
      disabledDate={handleDisabledDate}
      value={value ? dayjs(value) : undefined}
      suffixIcon={<CalenderIcon />}
      {...rest}
    />
  );
};
const SuffixPassword = (props) => {
  const ref = React.useRef();
  const [state, setState] = React.useState({ showCopy: false, copied: false });
  const {
    id,
    name,
    value,
    onChange,
    className,
    rootClassName,
    allowPasswordCopy,
    onCopyToClipboard,
    ...rest
  } = props;
  const handleCopyToClipboard = () => {
    if (allowPasswordCopy && state.showCopy) {
      navigator.clipboard.writeText(value.suffix + value.pass);
      setState((_) => ({ ..._, copied: true }));
      onCopyToClipboard && onCopyToClipboard(true);
    }
  };
  React.useEffect(() => {
    setState((_) => ({ ..._, showCopy: value.pass.length > 0, copied: false }));
  }, [value]);
  return (
    <div className="row suffix-pass-input">
      <div className="row pass-suffix v-ctr h-ctr">
        {value ? value.suffix : ""}
      </div>
      <TextInput
        ref={ref}
        name={name}
        value={value ? value.pass : ""}
        onChange={onChange}
        {...rest}
        suffix={
          <div style={{ opacity: allowPasswordCopy && state.showCopy ? 1 : 0 }}>
            {state.copied ? (
              <span className="f10 c44BB44">Copied</span>
            ) : (
              <CheckSquareOutlined
                style={{ fontSize: "14px" }}
                onClick={handleCopyToClipboard}
              />
            )}
          </div>
        }
      />
    </div>
  );
};
const CollapseInput = (props) => {
  const { label, children, onChange, options, formData, onExpand } = props;
  const [showExpanded, setShowExpanded] = useState(false);
  const handleExpand = () => {
    setShowExpanded(!showExpanded);
    onExpand && onExpand(showExpanded);
  };
  return (
    <>
      <div className="collapse-header w-100" onClick={handleExpand}>
        {showExpanded ? (
          <DownArrowIcon className="down-icon" />
        ) : (
          <RightArrowIcon className="down-icon" />
        )}
        <span className={props.labelClassName}>{label}</span>
      </div>
      {showExpanded && (
        <div className="collapse-content">
          {children.map((properties, i) => {
            const { label, attribute, type, labelClassName, ...rest } =
              properties;
            const p = {
              label,
              name: attribute,
              type,
              onChange,
              options,
              labelClassName,
              ...rest,
            };
            const value = formData[attribute];
            return <Input {...p} key={i} value={value} />;
          })}
        </div>
      )}
    </>
  );
};

const CheckBox = (props) => {
  const { label, name, labelClassName, onChange, value } = props;
  const handleChange = (e) => {
    onChange && onChange({ target: { name, value: e.target.checked } });
  };
  return (
    <AntCheckBox checked={value || false} onChange={handleChange}>
      <span className={`f14 reg cooo85 label ${labelClassName || ""}`}>
        {label}
      </span>
    </AntCheckBox>
  );
};
const DragDropFile = (props) => {
  const { className, title, subtitle, name, onChange, value } = props;
  const handleChange = (e) => {
    onChange && onChange({ target: { name, value: e.fileList } });
  };
  return (
    <div className={`col w-100 bgFAFAFA drag-drop-card ${className || ""}`}>
      <Upload.Dragger
        action={null}
        fileList={value}
        accept={props.accept}
        onChange={handleChange}
        className="col w-100"
        multiple={props.multiple}
        showUploadList={false}
        beforeUpload={() => false}
      >
        <div className="col w-100 h-100 v-ctr h-ctr content">
          <InboxOutlined style={{ fontSize: 35, color: "#0133CC" }} />
          {Boolean(title) && (
            <span
              className="f16 line-12 title c00085"
              dangerouslySetInnerHTML={{ __html: title }}
            ></span>
          )}
          {Boolean(subtitle) && (
            <span
              className="f10 line-22 subtitle c00045"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            ></span>
          )}
        </div>
      </Upload.Dragger>
    </div>
  );
};
export const Input = (props) => {
  const {
    className,
    id,
    label,
    name,
    labelClassName,
    showWarningIcon,
    ...rest
  } = props;
  const type = props.type || "text";
  const noLabel = !Boolean(label) ? "no-label" : "";
  const canShowLabel = () => {
    return type !== "checkbox";
  };
  return (
    <>
      <AntForm.Item
        help={<HelpText {...props} />}
        htmlFor={`for-${id || name}`}
        colon={false}
        className={`form-input ${
          props.formClassName && props.readOnly
            ? props.formClassName
            : "form-input"
        } type-${type} ${noLabel} field-${name} ${className || ""}`}
        label={
          canShowLabel() ? (
            showWarningIcon ? (
              <Row
                align="middle"
                gutter={8}
                className={`${labelClassName} d-flex`}
              >
                <Col className="flex-grow-1">
                  <Label text={label} {...rest} />
                </Col>
                <Col>
                  <WarningOutlined style={{ color: "orange" }} />
                </Col>
              </Row>
            ) : (
              <Label text={label} className={labelClassName} {...rest} />
            )
          ) : undefined
        }
      >
        {type === "text" && <TextInput {...props} className="" />}
        {type === "select" && <Select {...props} />}
        {type === "autocomplete" && <AutoComplete {...props} />}
        {type === "textarea" && <TextArea {...props} />}
        {type === "file" && <FileInput {...props} />}
        {type === "html" && <HTMLInput {...props} />}
        {type === "date" && <DatePicker {...props} />}
        {type === "suffix-pass" && <SuffixPassword {...props} />}
        {type === "checkbox" && <CheckBox {...props} />}
        {type === "drag-drop-file" && <DragDropFile {...props} />}
      </AntForm.Item>
      {Boolean(props.readOnly) && props.showBottomBorder && (
        <div>
          <hr style={{ borderColor: "#E8E8E8", borderWidth: "0.1px" }} />
        </div>
      )}
    </>
  );
};

const validationHelper = (fields, values) => {
  let errors = {},
    valid = true;
  for (let i = 0; i < fields.length; i++) {
    const { attribute, required, label } = fields[i];
    if (required && !values[attribute]) {
      errors[attribute] = `Please Enter the ${label || ""}`;
      valid = false;
    }
  }
  return { errors, valid };
};
export const Form = React.forwardRef((props, ref) => {
  const [errors, setErrors] = React.useState({});
  const {
    direction,
    Fields,
    formData,
    onChange,
    onExpand,
    getOptions,
    onSubmit,
    className,
    showColon,
    hideErrorMsg,
    labelAlign,
  } = props;
  const formDisabled = props.disabled;
  // Update errors when new errors are received from props
  React.useEffect(() => {
    setErrors(props.errors || {});
  }, [props.errors]);

  const handleSubmit = (e, skipSubmit) => {
    e && e.preventDefault && e.preventDefault();
    const { valid, errors } = validationHelper(Fields, formData);
    if (!valid) {
      if (!Boolean(hideErrorMsg)) {
        setErrors(errors);
      }
      return;
    }
    if (!skipSubmit) {
      onSubmit && onSubmit();
    }
    return valid;
  };
  const handleChange = (e) => {
    const { name } = e.target;
    const _errors = { ...errors };
    _errors[name] = false;
    setErrors(_errors);
    onChange && onChange(e);
  };
  const handleExpand = (attribute, expanded) => {
    onExpand && onExpand({ name: attribute, expanded });
  };
  React.useImperativeHandle(ref, () => ({ validate: handleSubmit }));
  return (
    <AntForm
      labelWrap
      labelAlign={labelAlign || "left"}
      layout={direction || "horizontal"}
      rootClassName={`w-100 ${className || ""}`}
      onFinish={handleSubmit}
      noValidate
    >
      {Fields.map(({ attribute, className, disabled, ...rest }, i) => {
        let options =
          typeof getOptions === "function" ? getOptions(attribute) : [];
        const p = {
          value: formData[attribute] || "",
          key: attribute,
          error: errors && errors[attribute],
          className: `${className || "w-100"}`,
          name: attribute,
          onChange: handleChange,
          options: options,
          showColon: showColon,
          disabled: disabled || formDisabled,
          ...rest,
          onExpand: handleExpand.bind(null, attribute),
        };
        if (
          p.type === "html" &&
          typeof props.onHtmlEditorReady === "function"
        ) {
          p.onInstanceReady = props.onHtmlEditorReady.bind(null, attribute);
        }
        if (p.type === "file" && props.onDeleteFile) {
          p.onDelete = props.onDeleteFile.bind(null, attribute);
        }
        if (p.type === "suffix-pass" && props.onCopyToClipboard) {
          p.onCopyToClipboard = props.onCopyToClipboard.bind(attribute);
        }
        return p.type === "collapse" ? (
          <CollapseInput
            {...p}
            key={i}
            children={rest.children}
            formData={formData}
          />
        ) : (
          <Input {...p} key={i} />
        );
      })}
    </AntForm>
  );
});
