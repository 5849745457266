import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PushpinOutlined,
  PushpinFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { DataTable, Form, PrimaryButton } from "../../components";
import Fields from "./FormFields.json";
import { ResponderCell, TitleCell } from "./Menu";
import {
  commonActions,
  getTaskSourceTypes,
  taskActions,
  getTasks,
  getTasksTotalCount,
  getAuditAssessmentsList,
  getTaskSourcesFromType,
  isMiscLoaded,
  getActivityTypeList,
  getCustomTypeList,
  getIncidentTypeList,
  getContinuityTypeList,
  getComplianceTask,
  getTaskBlueprintTemplates,
  getTaskCreatedBlueprintTemplates,
  isTasksFetched,
} from "../../store";
import { debounce } from "lodash";
import { OvalLoading } from "../../components/Screens";

const TASKS_PER_PAGE = process.env.REACT_APP_TASK_PER_PAGE || 9;

const Columns = [
  {
    title: "Task ID",
    dataIndex: "task_id_pin",
    key: "task_id_custom",
    width: "170px",
    className: "no-wrap",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
    width: "140px",
    sort: true,
    render: "color-code",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "140px",
    sort: true,
    render: "color-code",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: "53%",
    search: true,
    supportServerSearch: true,
    classNames: "",
    render: (text) => <TitleCell text={text} />,
  },
  {
    title: "Due Date",
    dataIndex: "due_date_str",
    key: "due_date_str",
    width: "150px",
    sort: true,
  },
  {
    title: "Aging",
    dataIndex: "aging",
    key: "aging",
    width: "80px",
    sort: "agingNum",
    classNames: { cell: "txt-right" },
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
    sort: true,
    width: "150px",
  },
  {
    title: "Responder",
    dataIndex: "responder",
    key: "responder",
    width: "175px",
    sort: "main_responder",
    render: ResponderCell,
  },
  // { title: 'Frequency', dataIndex: 'repetition_occurs', key: 'repetition_occurs', width: '120px', },
];
const getPage = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get("page") || "1";
};

export const TaskTracker = () => {
  const page = getPage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const _ref = React.useRef(null);
  const tasks = useSelector(getTasks) || [];
  const types = getTaskSourceTypes();
  const complainceTypes = getComplianceTask();
  const isLoaded = useSelector(isMiscLoaded) || false;
  const taskCount = useSelector(getTasksTotalCount);
  const audits = useSelector(getAuditAssessmentsList);
  const activities = useSelector(getActivityTypeList);
  const customTypes = useSelector(getCustomTypeList);
  const incidents = useSelector(getIncidentTypeList);
  const continuityTests = useSelector(getContinuityTypeList);
  const bpList = useSelector(getTaskBlueprintTemplates.bind(null, true));
  const bpCreatedList = useSelector(getTaskCreatedBlueprintTemplates);
  const [typeFilter, setTypeFilter] = React.useState({ type: "", search: "" });
  // Initialize currentPage based on the URL
  const [currentPage, setCurrentPage] = React.useState(getPage());
  const taskListFetched = useSelector(isTasksFetched);

  const sources = useSelector(
    getTaskSourcesFromType.bind(
      null,
      true,
      typeFilter.type && typeFilter.type.id
    )
  );
  const _filter = React.useRef({
    pageSize: TASKS_PER_PAGE,
    searchText: "",
    search: {},
    query: {},
    // pageNo: page, //old
    pageNo: currentPage,
    // defaultPage: page === "recent" ? 1 : Number(page) + 1, //old
    removeCount: true,
    enableRecent: true,
  });
  React.useEffect(() => {
    dispatch(commonActions.fetchDepartments());
    dispatch(commonActions.fetchTaskPriorities());
    dispatch(commonActions.fetchTaskStatus());
    dispatch(taskActions.fetchRepeatitionList());
    dispatch(taskActions.fetchDashboard());
    dispatch(taskActions.fetchAuditAssessmentsList());
    dispatch(taskActions.fetchActivityTypeList());
    dispatch(taskActions.fetchCustomTypeList());
    dispatch(taskActions.fetchIncidentTypeList());
    dispatch(taskActions.fetchContinuityTypeList());
    dispatch(taskActions.fetchTaskBlueprintTemplates());
    dispatch(taskActions.fetchTaskCreatedBlueprintTemplates());
  }, []);

  React.useEffect(() => {
    if (isLoaded) {
      loadTasksFromQuery(); // Trigger once when data is loaded
    }
  }, [isLoaded]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type") || "";
    const search = queryParams.get("search") || "";
    const page = getPage();
    setTypeFilter({ type, search });
    _filter.current.pageNo = page;
    _filter.current.searchText = search;
    setCurrentPage(page);
  }, [location.search]);

  const loadTasksFromQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const searchText = queryParams.get("search") || "";
    const pageNo = queryParams.get("page") || "1";

    _filter.current.searchText = searchText;
    _filter.current.pageNo = pageNo;

    loadTasks();
  };
  const debouncedLoadTasks = debounce((filter) => {
    updateQueryString();
    dispatch(taskActions.fetchAllTasks(filter));
  }, 500);
  const updateQueryString = () => {
    const { pageNo, query, searchText } = _filter.current;
    const searchParams = new URLSearchParams("");
    Object.keys(query).forEach((key) => {
      searchParams.set(key, query[key]);
    });
    if (searchText) {
      searchParams.set("search", searchText);
    }
    searchParams.set("page", String(pageNo));
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
  };

  const loadTasks = () => {
    const { pageNo, search, searchText, pageSize, removeCount } =
      _filter.current;
    const payload = { searchText, pageNo, search, pageSize, removeCount };
    debouncedLoadTasks(payload);
  };
  const getFilterFields = () => {
    const fields = Fields.Filter.map((_) => ({ ..._ }));
    if (typeFilter.type) {
      fields[1].type = "select";
      fields[1].label = "Source";
    }
    return fields;
  };
  const getOptions = (attribute) => {
    let options = [];
    if (attribute === "type") {
      options = [...types, ...complainceTypes];
    }
    if (attribute === "search") {
      if (typeFilter.type.id === "blueprint") {
        options = bpCreatedList;
      } else {
        options = sources;
      }
    }
    return options;
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    let filter = { ...typeFilter };
    filter[name] = value;

    // If the type is changed, reset the search field
    if (name === "type") {
      filter.search = "";
    }
    setTypeFilter(filter);
    // Check if both fields are cleared
    if (!filter.type && !filter.search) {
      // Trigger re-fetch of all tasks with no filters
      _filter.current = {
        ..._filter.current,
        search: {},
        query: {},
        searchText: "",
        enableRecent: true,
        pageNo: "1",
        removeCount: true,
        defaultPage: 1,
      };
      loadTasks();
    }
  };

  const handleSearchFilter = (e, queryFilter = {}) => {
    let pageNo;
    // _filter.current.searchText = '';
    // Clear previous filters
    types.forEach(({ fetchKey, queryKey }) => {
      delete _filter.current.search[fetchKey];
      delete _filter.current.query[queryKey];
    });
    const taskFilter = { ...typeFilter, ...queryFilter };
    // Check if both fields are cleared
    if (!taskFilter.type && !taskFilter.search) {
      // No filters applied, re-fetch all tasks
      _filter.current = {
        ..._filter.current,
        search: {},
        query: {},
        searchText: "",
        pageNo: "1",
        enableRecent: true,
        removeCount: true,
      };
      loadTasks();
      return;
    }
    // Apply filtering logic if fields are not empty
    if (taskFilter.search) {
      if (taskFilter.type) {
        let { id, guid } = taskFilter.search;
        let { fetchKey, queryKey } = taskFilter.type;
        _filter.current.search[fetchKey] = id;
        _filter.current.query[queryKey] = guid || id;
      } else {
        _filter.current.searchText = taskFilter.search;
      }
      _filter.current.enableRecent = false;
      _filter.current.defaultPage = 1;
      pageNo = 1;
    } else {
      _filter.current.enableRecent = true;
      pageNo = "recent";
    }
    //if only type filter is there not source
    if (taskFilter.type) {
      let { label } = taskFilter.type;
      _filter.current.searchText = label;
      pageNo = 1;
    }
    // console.log("_filter", _filter);

    _filter.current.pageNo = pageNo;
    _filter.current.removeCount = true;
    _filter.current.defaultPage = pageNo === "recent" ? 1 : Number(pageNo) + 1;

    loadTasks();
  };

  const handlePageChange = (pageNo) => {
    _filter.current.pageNo = pageNo;
    setCurrentPage(pageNo);
    loadTasks();
  };

  const handleSearch = (colDataIndex, searchText) => {
    _filter.current.searchText = searchText;
    _filter.current.pageNo = "1"; // Reset to first page when searching
    loadTasks();
  };
  const handlePinTask = (taskId, pinStatus) => {
    const updatedVal = { pin_task: pinStatus };
    dispatch(
      taskActions.updateTask({
        taskId,
        updatedVal,
        popup: {
          message: pinStatus ? "Task Pinned Successfully" : "Task Unpinned",
        },
        callback: () => {
          // setCurrentPage(1); // Reset to page 1 in component state
          navigate(`${window.location.pathname}?page=1`); // Navigate to page=1 in URL
        },
      })
    );
  };
  const modifyTasks = tasks.map((task) => {
    const temp = { ...task };
    const Icon = temp["pin_task"] ? PushpinFilled : PushpinOutlined;
    temp["task_id_pin"] = (
      <div className="row w-100">
        <Link
          to={{
            pathname: temp.guid,
            state: { from: location.pathname + location.search },
          }}
          className="c1133CC"
        >
          {temp["task_id"]}
        </Link>
        {_filter.current.pageNo !== "recent" && (
          <Icon
            style={{ color: "#1133CC", marginLeft: 12 }}
            onClick={() =>
              handlePinTask(temp["id"], !Boolean(temp["pin_task"]))
            }
          />
        )}
      </div>
    );
    return temp;
  });

  return (
    <div className="col w-100 h-100 o-hide tracker" ref={_ref}>
      <div className="row tracker-filter ">
        <Form
          className="row "
          Fields={getFilterFields()}
          getOptions={getOptions}
          formData={typeFilter}
          onChange={handleFilterChange}
        />
        <PrimaryButton
          className="search-btn"
          onClick={handleSearchFilter}
          icon={<SearchOutlined style={{ fontSize: 14, color: "#FFF" }} />}
        />
      </div>
      {!taskListFetched ? (
        <div
          className="col w-100 h-100"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OvalLoading isSmall />
        </div>
      ) : (
        <DataTable
          noSelection
          emptyMessage="There are no Tasks right now!"
          Columns={Columns}
          rows={modifyTasks}
          totalCount={taskCount}
          onSearch={handleSearch}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          paginationConfig={{
            enableRecent: _filter.current.enableRecent,
            defaultPage: _filter.current.defaultPage,
            pageSize: _filter.current.pageSize,
          }}
        />
      )}
    </div>
  );
};
