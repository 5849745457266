import React, { useEffect, useRef } from "react";
import { App, Modal, Progress } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  NavBar,
  AppErrorBoundry,
  OvalLoading,
  PageNotFound,
  AccessRestricted,
  useSub,
} from "../../components";
import {
  userActions,
  getCurrentUser,
  getAlert,
  AppActions,
  isLoggedOut,
  isProgress,
  isAdmin,
  isArchitect,
  encyptDataObject,
} from "../../store";

const ConsoleEndPoint = `https://console-sandbox.${process.env.REACT_APP_MAIN_DOMAIN}`;
const MODULE_ID = "arc";

export const PageNotFoundScreen = (props) => {
  const navigate = useNavigate();
  return <PageNotFound onHome={() => navigate("/")} />;
};
export const AccessRestrictedScreen = (props) => {
  const navigate = useNavigate();
  return <AccessRestricted onHome={() => navigate("/")} />;
};

export const HomeScreen = (props) => {
  const { notification, modal } = App.useApp();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(getCurrentUser);
  const appAlert = useSelector(getAlert);
  const showProgress = useSelector(isProgress);
  const loggedOut = useSelector(isLoggedOut);
  const navigateToLogin = () => {
    let isLocalhost = process.env.REACT_APP_LOCALHOST,
      path = ConsoleEndPoint + "/login",
      moduleId = null,
      redirectData = {};

    moduleId = isLocalhost ? "localhost" : !loggedOut ? MODULE_ID : null;
    if (moduleId) {
      path += "?source=" + moduleId;
      const { pathname, search } = location;
      if (pathname !== "") {
        redirectData.path = pathname;
      }
      if (search !== "") {
        redirectData.search = search;
      }
      if (Object.keys(redirectData).length > 0) {
        let redirect = encyptDataObject(redirectData);
        path += `&redirect=${redirect}`;
      }
    }
    window.location.assign(path);
  };
  useEffect(() => {
    document.title = "Arc | Sandbox";
    dispatch(userActions.validateToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (appAlert) {
      if (appAlert.type === "modal") {
        let modalConfig = {
          title: appAlert.message,
          content: appAlert.description,
          okText: "OK",
          onOk: clearNotification,
        };
        if (appAlert.severity === "warning") {
          modal.warning(modalConfig);
        } else if (appAlert.severity === "error") {
          modal.error(modalConfig);
        } else if (appAlert.severity === "success") {
          modal.success(modalConfig);
        }
      } else {
        const config = {
          duration: 2,
          message: appAlert.message,
          onClose: clearNotification,
        };
        if (appAlert.success === false) {
          notification.error(config);
        } else {
          notification.success(config);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appAlert]);
  useEffect(() => {
    if (user === "Error") {
      navigateToLogin();
    }
    // else if (user) {
    //   dispatch(arcActions.fetchDefinedList())
    // }
  }, [user]);
  useEffect(() => {
    if (loggedOut) {
      navigateToLogin();
    }
  }, [loggedOut]);
  useSub("SESSION_LOGOUT", () => {
    dispatch(userActions.logout());
  });
  const clearNotification = (e, reason) => {
    if (reason !== "clickaway") {
      dispatch(AppActions.setAlert(null));
      if (appAlert.type === "modal") {
        Modal.destroyAll();
      }
    }
  };
  return (
    <div className="col w-100 h-100 main-screen white o-hide">
      {Boolean(user && user !== "Error") ? (
        <>
          <NavBar
            currentModule={MODULE_ID}
            user={user}
            isAdmin={isAdmin(user)}
            isArchitect={isArchitect(user)}
          />
          <div
            className={`api-progress-container ${
              showProgress ? "show" : ""
            } -mt1`}
          >
            <Progress
              percent={showProgress ? 100 : 0}
              showInfo={false}
              strokeColor="#1E686D"
              strokeWidth={3}
              className={`api-progress c1E686D ${showProgress ? "show" : ""}`}
            />
          </div>
          <main className="col flex-full o-hide ">
            <AppErrorBoundry>{props.children}</AppErrorBoundry>
          </main>
        </>
      ) : (
        <div
          className="col w-100 h-100"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OvalLoading />
        </div>
      )}
    </div>
  );
};
