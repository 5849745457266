import { useEffect, useState } from "react";
import { TableComponent } from "../../StatsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getPriorityStats,
  getCurrentUser,
  getDepartments,
  taskActions,
  commonActions,
} from "../../../store";

const Field = { attribute: "dateRange", type: "select" };

const initPriorityRows = [
  {
    priority: "Critical",
    total: 0,
    complete: 0,
    inprogress: 0,
    pending: 0,
    other: 0,
    labelClassName: "Critical",
  },
  {
    priority: "High",
    total: 0,
    complete: 0,
    inprogress: 0,
    pending: 0,
    other: 0,
    labelClassName: "High",
  },
  {
    priority: "Medium",
    total: 0,
    complete: 0,
    inprogress: 0,
    pending: 0,
    other: 0,
    labelClassName: "Medium",
  },
  {
    priority: "Low",
    total: 0,
    complete: 0,
    inprogress: 0,
    pending: 0,
    other: 0,
    labelClassName: "Low",
  },
  {
    priority: "Minimal",
    total: 0,
    complete: 0,
    inprogress: 0,
    pending: 0,
    other: 0,
    labelClassName: "Minimal",
  },
];

const initRowClassName = {
  Critical: "Critical",
  High: "High",
  Medium: "Medium",
  Low: "Low",
  Minimal: "Minimal",
  Default: "Default",
  Emergency: "Emergency",
  Urgent: "Urgent",
  Least: "Least",
};
export const PriorityVsComplianceStats = (props) => {
  const arcStats = props;
  const [responseData, setResponseData] = useState({});
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const departments = useSelector(getDepartments);
  const priorityStats = useSelector(getPriorityStats);

  const deptInitialRows = [...initPriorityRows];
  const PriorityColumns = [
    {
      label: "",
      attribute: "priority",
      headerClassName: "txt-left",
      isLabel: true,
      width: "7%",
    },
    {
      label: "Max Aging<br/>(Days)",
      attribute: "max_aging",
      width: "8%",
      headerClassName: "bgDDFFFF",
    },
    {
      label: "Avg Aging<br/>(Days)",
      attribute: "avg_aging",
      width: "8%",
      headerClassName: "bgDDFFFF",
    },
    {
      label: "Total",
      attribute: "Total",
      headerClassName: "bgDDFFFF",
      width: "8%",
    },
    {
      label: "Complete",
      attribute: "Complete",
      headerClassName: "",
      width: "8%",
      colorIndicator: true,
    },
    {
      label: "Started",
      attribute: "Started",
      headerClassName: "",
      width: "8%",
      colorIndicator: true,
    },
    {
      label: "Pending",
      attribute: "Pending",
      headerClassName: "",
      width: "8%",
      colorIndicator: true,
    },
    {
      label: "Other",
      attribute: "Other",
      headerClassName: "",
      width: "8%",
      colorIndicator: true,
    },
  ];

  const [tableData, setTableData] = useState([...deptInitialRows]);
  useEffect(() => {
    dispatch(commonActions.fetchDepartments());
  }, []);
  const getDataFromServer = async (dateRange) => {
    let payload = {
      date_range_type: "last_365_days",
      start_date: "01-11-2023",
      end_date: "20-11-2024",
      stats_type: "priority_stats",
    };
    if (dateRange && dateRange.id) {
      payload.date_range_type = dateRange.id;
    }
    try {
      dispatch(taskActions.fetchArcStats(payload));
    } catch (error) {
      console.error("Error fetching data from server:", error);
    }
  };
  useEffect(() => {
    // Prioritize priorityStats if available, otherwise use arcStats
    if (arcStats?.priority_status_list) {
      setResponseData(arcStats.priority_status_list);
    } else if (priorityStats && Object.keys(priorityStats).length > 0) {
      setResponseData(priorityStats);
    } else {
      setResponseData({});
    }
  }, [arcStats, priorityStats]);

  useEffect(() => {
    if (responseData && user && departments) {
      let tempTableData = getDataFromResponse(responseData, user, departments);
      setTableData([...tempTableData]);
    }
  }, [responseData, user, departments]);

  const DeptTableID = "arc-priority-table";

  return (
    <>
      <TableComponent
        title="Priority-wise Compliance Status"
        topBarField={Field}
        rows={tableData}
        Columns={PriorityColumns}
        tableField={Field}
        tableID={DeptTableID}
        tableClassName="arc-stats-tbl"
        onDateChange={getDataFromServer}
      />
    </>
  );
};

const getDataFromResponse = (res, user, departments) => {
  if (!res) return [];

  const brandname = user.ent_org.brandname;

  // Initialize the totalRow
  let totalRow = {
    priority: brandname,
    max_aging: 0,
    avg_aging: 0,
    Total: 0,
    Complete: 0,
    Started: 0,
    Pending: 0,
    Other: 0,
    labelClassName: "bgTotal",
  };
  let output = [];
  const responseData = { ...res };
  const displayPriorities = [
    "Emergency",
    "Urgent",
    "Critical",
    "High",
    "Medium",
    "Low",
    "Minimal",
    "Least",
  ];

  const allPriorities = [
    "Emergency",
    "Urgent",
    "Critical",
    "Criticall",
    "High",
    "Medium",
    "Default",
    "Low",
    "Minimal",
    "Least",
  ];

  // Generate rows for all priorities
  const outputArray = allPriorities.map((priorityKey) => {
    let row = {
      Total: 0,
      Complete: 0,
      Started: 0,
      Pending: 0,
      Other: 0,
      max_aging: 0,
      avg_aging: 0,
      labelClassName: initRowClassName[priorityKey] || "Default",
      priority: priorityKey,
    };

    const priorityData = responseData[priorityKey];
    if (priorityData) {
      for (const [statusKey, value] of Object.entries(priorityData)) {
        if (
          statusKey === "Pending" ||
          statusKey === "Started" ||
          statusKey === "Complete"
        ) {
          row[statusKey] = value;
          row["Total"] += value;
        } else if (
          ["Closed", "Duplicate", "Open", "Future"].includes(statusKey)
        ) {
          row["Other"] += value; // Aggregate statuses like Closed, Duplicate, etc.
          row["Total"] += value;
        } else if (
          statusKey !== "total" &&
          statusKey !== "max_aging" &&
          statusKey !== "avg_aging"
        ) {
          row["Other"] += value;
          row["Total"] += value;
        }

        if (statusKey === "max_aging") {
          row["max_aging"] = Math.max(row["max_aging"], value);
        }
        if (statusKey === "avg_aging" && value > 0) {
          row["avg_aging"] = value;
        }
      }
    }

    return row;
  });

  // Aggregate totalRow values
  outputArray.forEach((row) => {
    totalRow["Total"] += row["Total"];
    totalRow["Complete"] += row["Complete"];
    totalRow["Started"] += row["Started"];
    totalRow["Pending"] += row["Pending"];
    totalRow["Other"] += row["Other"];
    totalRow["max_aging"] = Math.max(totalRow["max_aging"], row["max_aging"]);

    if (row["avg_aging"] > 0) {
      totalRow["avg_aging"] += row["avg_aging"];
    }
  });

  // Calculate the average for avg_aging
  const validAvgAgingRows = outputArray.filter((row) => row["avg_aging"] > 0);
  totalRow["avg_aging"] =
    validAvgAgingRows.length > 0
      ? Math.ceil(totalRow["avg_aging"] / validAvgAgingRows.length)
      : "na";

  // Filter rows for display priorities and remove rows with all zero values
  output = [
    totalRow,
    ...outputArray.filter((row) => displayPriorities.includes(row.priority)),
  ];

  output = output.filter((row) => {
    const values = Object.entries(row)
      .filter(([key]) => key !== "priority")
      .map(([_, value]) => parseInt(value, 10) || 0);
    return values.some((value) => value !== 0);
  });

  // Replace zeros with "-"
  output.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (
        key !== "priority" &&
        key !== "labelClassName" &&
        (row[key] === 0 || row[key] === "0")
      ) {
        row[key] = "-";
      }
    });
  });

  return output;
};
